import './stars.background.css';
export function StarsBackground() {
    return (
        <section className="stars__background">
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
            <div id="stars4"></div>
        </section>
    );
}
